import React from "react";

// Child Components
import Hero from "../../../components/hero/automation";
import Testimonial from "../../../components/clientsOpinions";
import Featured from "../../../components/featured/text";
import Services from "../../../components/services";
import FeaturedCam from "../../../components/featured/text";
import FeaturedTablet from "../../../components/featured/withBkg";

export default function SmartHomeAutomationPage() {
  return (
    <>
      <Hero />
      <Testimonial />
      <Featured />
      <Services />
      <FeaturedCam />
      <FeaturedTablet />
    </>
  );
}
