import React from "react";

export default function WithBtk() {
  return (
    <div className="layout-side-by-side cf-component lighter-gray image-to-bottom">
      <div className="container">
        <div className="row">
          <section className="image-container small-12 medium-6 large-6 columns">
            <img
              src={process.env.PUBLIC_URL + "images/iphone-sec.png"}
              alt="phone"
            />
          </section>
          <section className="content-container small-12 medium-6 large-6 columns">
            <h3>Simplifica tu vida diaria</h3>
            <p>
              En Vivint, nuestro objetivo no es solamente proteger tu casa sino
              mejorar tu calidad de vida. Con Vivint Smart Control siempre
              estar&#225;s al tanto de lo que sucede en tu hogar. Podr&#225;s
              supervisar a tu ni&#241;era, ver lo que est&#225;n haciendo tus
              mascotas o desactivar a distancia el seguro de tu puerta para
              dejar pasar a un amigo, sin importar d&#243;nde te encuentres.
              Controla los seguros de tus puertas, tus peque&#241;os
              electrodom&#233;sticos, termostatos y l&#225;mparas utilizando tu
              Smartphone o cualquier dispositivo con conexi&#243;n a Internet.
              Respira tranquilo mientras est&#225;s lejos de casa.
            </p>
            <ul>
              <li>
                Ahorra dinero en tus facturas de electricidad con Smart Energy
                <sup>[1]</sup>
              </li>
              <li>Vigila tu casa desde cualquier parte</li>
              <li>Mantente conectado con tus seres queridos y mascotas</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}
