import React from "react";

export default function Landing() {
  return (
    <div className="layout-feature cf-component hero">
      <div className="container">
        <div className="row">
          <div className="columns"></div>
          <div className="feature-content columns">
            <h1>
              Protege tu hogar y ahorra con
              <span>
                un Sistema personalizable de Vivint.SmartHome<sup>&trade;</sup>
              </span>
            </h1>
            <a href="tel:1-855-950-1599">
              <p>
                Llama ahora. <span>1-855-950-1599</span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
