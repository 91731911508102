import React from "react";

export default function Cam() {
  return (
    <>
      <div
        className="layout-feature cf-component hero"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "images/home-services/doorbell-hero.png"
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="columns"></div>
            <div className="feature-content columns">
              <h1>
                <span>Protege lo que m&#225;s te importa con</span> El timbre
                con c&#225;mara de Vivint
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Conoce más acerca de Vivint.{" "}
                  <strong>Llama al 1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Call now. <strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
