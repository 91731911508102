import React from "react";
import { Link } from "react-router-dom";

export default function Services() {
  return (
    <>
      <div className="layout-feature cf-component white">
        <div className="container">
          <div className="row">
            <div className="columns"></div>
            <div className="feature-content columns">
              <h3>Elige el mejor equipo que Vivint tiene para ofrecer</h3>
              <p>
                Los paquetes Vivint Smart Control te ofrecen el m&#225;s moderno
                <Link to="/equipo">
                  equipo de automatizaci&#243;n residencial
                </Link>
                .
              </p>
              <h4>Personaliza tu paquete con:</h4>
              <ul className="bullet-right">
                <li>Sensor de puerta/ventana</li>
                <li>Detector de movimiento</li>
                <li>Detector de vidrio roto</li>
                <li>Collar de alerta m&#233;dica</li>
                <li>Detector de humo</li>
              </ul>
              <ul className="bullet-left">
                <li>Sensor en puertas/ventanas corredizas</li>
                <li>Sensor de inclinaci&#243;n</li>
                <li>Llavero</li>
                <li>Detector de Mon&#243;xido de Carbono</li>
                <li>Sensor de temperatura/inundaci&#243;n</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-5-column cf-component equipment-preview">
        <div className="container">
          <div className="row">
            <div className="before columns">
              <h4>Servicios residenciales est&#225;ndar</h4>
            </div>
          </div>
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 small-6 medium-2 medium-offset-1 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/doorbell-camera.png"}
                  alt="Doorbell Camera"
                />
                <p>C&#225;mara para timbre</p>
              </div>
              <div className="all column-2 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/indoor-camera.png"}
                  alt="Indoor Camera"
                />
                <p>C&#225;mara para interiores</p>
              </div>
              <div className="all column-3 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/thermostat.png"}
                  alt="Thermostat"
                />
                <p>Termostato</p>
              </div>
              <div className="all column-4 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/dead-bolt.png"}
                  alt="Dead Bolt"
                />
                <p>Candado de puertas/cerrojo de seguridad</p>
              </div>
              <div className="all column-5 small-6 medium-2 end columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/garage-door.png"}
                  alt="Garage Door"
                />
                <p>Control para puerta de garaje</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-2-column cf-component equipment-preview">
        <div className="container">
          <div className="row">
            <div className="before columns">
              <h4>Premium Smart Home Services</h4>
            </div>
          </div>
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 small-6 medium-2 medium-offset-4 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/outdoor-camera.png"}
                  alt="Outdoor Camera"
                />
                <p>Outdoor Camera</p>
              </div>
              <div className="all column-2 small-6 medium-2 end columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/space-monkey.png"}
                  alt="Space Monkey"
                />
                <p>
                  Vivint Smart Drive<sup>&reg;</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Lama ahora y obtén una estimación GRATIS.
                  <strong>1-855-950-1599</strong>
                </span>
                <span className="text-mobile">
                  Llama ahora: <strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
