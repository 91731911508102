import React from "react";
import { Link } from "react-router-dom";

export default function AllItems() {
  return (
    <div className="layout-side-by-side cf-component light-content reverse white">
      <div className="container">
        <div className="row">
          <section className="image-container small-12 medium-6 large-6 columns">
            <img
              src={process.env.PUBLIC_URL + "images/equipment2.png"}
              alt="all"
            />
          </section>
          <section className="content-container small-12 medium-6 large-6 columns">
            <h3>
              Un completo sistema inteligente para el hogar por menos de $2
              diarios. <sup>&deg;</sup>
            </h3>
            <p>
              &#191;Quieres instalar r&#225;pidamente tu Sistema de Alarma
              Vivint? No dejes que los costos te detengan. De hecho, puedes
              obtener un completo sistema inteligente para el hogar por menos de
              $2 diarios, a trav&#233;s del proveedor n&#250;mero 1 de los
              Estados Unidos.* Paga tu equipo por adelantado o fin&#225;ncialo
              con un 0% de inter&#233;s a un plazo de 42 o 62 meses. Llama al
              1-855-950-1599 para m&#225;s informaci&#243;n.
            </p>
            <Link className="btn orange" to="paquetes">
              Ver Paquetes
            </Link>
          </section>
        </div>
      </div>
    </div>
  );
}
