import React from "react";

// Child Components
import Hero from "../../../components/hero/cam";
import Featureds from "../../../components/featured/camDoorIn";

export default function CamaraPuertaDeEntrada() {
  return (
    <>
      <Hero />
      <Featureds />
    </>
  );
}
