import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="layout-geo-finder-bar cf-component geo-jump">
        <div className="container row">
          <section className="small-12 medium-4 columns">
            <img
              src={process.env.PUBLIC_URL + "images/footer-logo.png"}
              alt="logo"
            />
            <p className="tfn"></p>
          </section>
          <section className="small-12 medium-8 columns">
            <form></form>
          </section>
        </div>
      </div>
      <div className="layout-4-column cf-component">
        <div className="container">
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 medium-3 columns">
                <h4>¡Pide Vivint hoy!</h4>
                <a href="tel:1-855-950-1599">
                  <h4>1-855-950-1599</h4>
                </a>
                <div className="layout-legal cf-component">
                  <p>
                    &copy; 2017 Nobrainer.com | Todos los derechos reservados.
                    <br />
                    <br />
                    Nobrainer, LLC es un revendedor autorizado de productos y
                    servicios de Vivint.
                  </p>
                </div>
              </div>
              <div className="all column-2 medium-3 columns">
                <h5>Paquetes</h5>
                <div className="links">
                  <Link to="paquetes">Descripción del paquete</Link>
                  <Link to="smart-home-automation">Smart Control</Link>
                </div>
              </div>
              <div className="all column-3 medium-3 columns">
                <h5>Equipo</h5>
                <div className="links">
                  <Link to="equipo">Descripción</Link>
                  <Link to="camara-puerta-de-entrada">
                    Camara Puerta de Entrada
                  </Link>
                </div>
              </div>
              <div className="all column-4 medium-3 columns">
                <h5>Acerca de</h5>
                <div className="links">
                  <Link to="ofertas-especiales">Ofertas Especiales</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="after columns">
              <div className="layout-legal cf-component">
                <p>
                  *Se requiere un contrato mínimo de servicio de $39.99/mes. Se
                  puede financiar un mínimo de $549 en compra de equipo con una
                  tasa de porcentaje anual (APR) de 0% por hasta 60 meses sin
                  pago inicial (Ejemplo: $9.15 al mes durante 60 meses en
                  $549.00 de equipo financiado). Sujeto a aprobación de crédito.
                  Contratos mensuales disponibles en la compra por adelantado
                  del equipo mínimo requerido. Opciones de compra y
                  financiamiento de equipo adicional. Las opciones de
                  financiamiento de 42 y 60 meses requieren un contrato de
                  servicio mensual que coincida con el acuerdo de financiamiento
                  disponible (Ejemplo: $39.99 al mes durante 60 meses = costo
                  total del servicio de $2,399.40). Las funciones de Hogar
                  Inteligente o Smart home comienzan desde $49.99 al mes y
                  requieren compra de equipo adicional o financiamiento. Sin un
                  plan de servicio de Vivint, se limita la funcionalidad de los
                  productos y el sistema (incluyendo la pérdida de conectividad
                  a distancia). Comunícate con un representante de Vivint al
                  teléfono que aparece en esta oferta para conocer todos los
                  detalles del equipo, el servicio, los paquetes y precios.
                  Servicios no disponibles en todos los estados. Pueden aplicar
                  impuestos y cuotas locales por permisos. Solo para nuevos
                  clientes de Vivint. Los productos y servicios en Louisiana son
                  operados por Vivint Louisiana, Certificado Comercial #58280.
                  Encuentra
                  <a
                    href="http://www.vivint.com/company/licenses"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aquí
                  </a>
                  los números de licencia de Vivint.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
