import React from "react";

export default function FullPicture() {
  return (
    <>
      <div
        className="layout-hero cf-component dark-gray overlay"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "images/installation-overlay.png"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="container row">
          <div className="content-wrapper small-12 medium-8 large-6 end columns">
            <section className="content">
              <h3>Instalaci&#243;n gratis* con los profesionales de Vivint</h3>
              <p>
                Configurar tu sistema de seguridad Vivint Home Smart no tiene
                por qu&#233; ser complicado. Vivint ofrece instalaci&#243;n
                gratis con profesionales certificados para garantizar un trabajo
                bien hecho. Despu&#233;s de una consulta personalizada, los
                profesionales de Vivint te dar&#225;n instrucciones,
                instalar&#225;n tu nuevo sistema de alarma y te brindar&#225;
                asistencia las 24 horas.
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  ¿Tienes preguntas? Llámanos. <strong>1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Haz clic para Llamar:<strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
