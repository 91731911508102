import React from "react";

// Child Components
import Hero from "../../../components/hero/landing";
import Prices from "../../../components/prices";
import ClientsOpinions from "../../../components/clientsOpinions";
import FeaturedWithOutBkg from "../../../components/featured/withOutBkg";
import FeaturedWithBkg from "../../../components/featured/withBkg";

export default function HomePage() {
  return (
    <>
      <Hero />
      <Prices />
      <ClientsOpinions />
      <FeaturedWithOutBkg />
      <FeaturedWithBkg />
    </>
  );
}
