import React from "react";

export default function Special() {
  return (
    <div className="layout-side-by-side cf-component image-to-bottom reverse gray">
      <div className="container">
        <div className="row">
          <section className="image-container small-12 medium-6 large-6 columns">
            <img
              src={process.env.PUBLIC_URL + "/images/app-thermostat.png"}
              alt="phone"
            />
          </section>
          <section className="content-container small-12 medium-6 large-6 columns">
            <h3>&#191;Qu&#233; tienen de especial los paquetes de Vivint?</h3>
            <p>
              Poder monitorear el termostato, las l&#225;mparas, y las emisiones
              de humo y mon&#243;xido de carbono de tu hogar con un sistema de
              comunicaciones inal&#225;mbricas, y adem&#225;s monitorear el
              sistema de seguridad de tu hogar por medio de video significa que
              te sentir&#225;s seguro y ahorrar&#225;s dinero.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
