import React from "react";

export default function Text() {
  return (
    <>
      <div className="layout-hero cf-component dark-gray overlay">
        <div className="container row">
          <div className="content-wrapper small-12 medium-8 large-6 end columns">
            <section className="content">
              <h3>
                Protege m&#225;s con el servicio de Automatizaci&#243;n
                Residencial Smart Control
              </h3>
              <p>
                With Vivint Smart Control Panel, you can control your home's
                locks, small appliances, lamps, and thermostat from your
                smartphone, no matter if you're down the street or halfway
                across the world. Never again will you have to stress over
                whether you left the coffee pot on or whether you'll be home in
                time to unlock the door for visitors. For that matter, imagine
                never coming home to a dark or cold home ever again. That's the
                power of Vivint smart home automation.
              </p>
              <p>
                Con el panel Smart Control de Vivint, puedes controlar los
                candados, peque&#241;os electrodom&#233;sticos, l&#225;mparas y
                termostato de tu hogar desde tu Smartphone, no importa si
                est&#225;s a unas calles de tu casa o al otro lado del mundo.
                Nunca m&#225;s tendr&#225;s que estresarte tratando de recordar
                si apagaste tu cafetera o preocuparte por regresar a casa a
                tiempo para abrirle la puerta a tus visitas. Y no solo eso,
                imag&#237;nate no tener que entrar a una casa oscura y
                fr&#237;a. Este es el poder de la automatizaci&#243;n
                residencial de Vivint.
              </p>
              <ul>
                <li>
                  Activa y desactiva el seguro de tus puertas cuando no
                  est&#225;s en casa
                </li>
                <li>
                  Controla tus l&#225;mparas y termostato con tu Smartphone
                </li>
                <li>
                  Enciende y apaga peque&#241;os electrodom&#233;sticos usando
                  tu Smartphone.
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Lama ahora y obtén una estimación GRATIS.{" "}
                  <strong>1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Llama ahora: <strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
