import React from "react";

export default function ClientsOpinions({ haveQuestion }: any) {
  return (
    <>
      <div className="layout-sandbox cf-component testimonials light-gray">
        <div className="container">
          <div className="row">
            <h3>Opiniones de Clientes</h3>
            <h6>
              Lee opiniones de clientes acerca de los productos y servicios de
              Vivint
            </h6>
            <div className="quotes">
              <div className="quote">
                <p>
                  El termostato nos ha ahorrado dinero utilizando los horarios
                  de ahorro de energía con pequeñas modificaciones.
                </p>
                <h6 className="quotee">Kristy L. | Illinois</h6>
              </div>
              <div className="quote">
                <p>
                  Elijo un producto o servicio por su precio, pero me quedo por
                  la gente.
                </p>
                <h6 className="quotee">Patrick D. | Ohio</h6>
              </div>
              <div className="quote">
                <p>
                  Mi sistema de automatización de Vivint me da el control de mi
                  hogar a cualquier hora y en cualquier lugar. La facilidad de
                  acceso es increíble.
                </p>
                <h6 className="quotee">Dennis C. | Arizona</h6>
              </div>
              <div className="quote">
                <p>
                  No me gusta llegar a mi casa en la oscuridad, por eso programé
                  las luces utilizando el módulo para pequeños electrodomésticos
                  de Vivint. Es excelente y me siento segura cuando regreso a
                  casa.
                </p>
                <h6 className="quotee">Elizabeth L. | Indiana</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {haveQuestion && (
        <div className="layout-call-to-action cf-component blue-gray has-desktop-click">
          <div className="container row">
            <div className="cta-wrapper small-12 columns">
              <h2>
                <a
                  href="tel:1-855-950-1599"
                  data-tracking="call-to-action__phone"
                >
                  <span className="text-main">
                    ¿Tienes preguntas? Llámanos. <strong>1-855-950-1599</strong>
                  </span>{" "}
                  <span className="text-mobile">
                    Haz clic para Llamar:<strong>1-855-950-1599</strong>
                  </span>
                </a>
              </h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
