import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header>
      <div className="sticky">
        <div className="layout-site-header cf-component pull-cta-right">
          <div className="container row">
            <Link
              to="/"
              className="brand-logo"
              data-tracking="site-header__logo"
            >
              <div
                style={{
                  position: "absolute",
                  width: 180,
                  height: 40,
                  zIndex: 9999,
                  cursor: "pointer",
                }}
              />
              <img
                alt="logo"
                src={process.env.PUBLIC_URL + "images/logo.png"}
              />
            </Link>
            <div className="right-block">
              <a
                href="tel:1-855-950-1599"
                className="cta-phone"
                data-mobile-cta-copy=""
                data-tracking="site-header__cta-phone"
              >
                <span className="cta-phone-copy">
                  Llama ahora: <strong>1-855-950-1599</strong>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="layout-navigation cf-component align-right row contained">
          <label
            className="menu-toggle"
            data-tracking="navigation__toggle--menu"
          >
            <img
              src="./images/icon-menu.png"
              className="responsive-menu-toggle-image"
              alt="Menu"
            />
            <span
              className="icon-dropdown menu-toggle"
              data-tracking="navigation__toggle--menu"
            ></span>
          </label>
          <input
            type="checkbox"
            id="menu-toggle-checkbox"
            className="menu-toggle-checkbox"
          />
          <ul className="menu clear hover">
            <li className="dropdown">
              <Link to="equipo" data-tracking="navigation__item">
                Equipo
                <img
                  alt="drop-down"
                  src={process.env.PUBLIC_URL + "images/nav-icon-down.png"}
                  style={{ marginLeft: 5 }}
                />
              </Link>
              <span
                className="icon-dropdown"
                data-tracking="navigation__item--dropdown--equipment-dropdown"
              ></span>
              <label
                className="icon-dropdown"
                data-tracking="navigation__item--dropdown--equipment-dropdown"
              ></label>
              <input type="checkbox" id="dropdown-equipment-dropdown" />
              <ul className="submenu">
                <li className="standard">
                  <Link to="equipo" data-tracking="navigation__item">
                    Sinopsis
                  </Link>
                </li>
                <li className="standard">
                  <Link
                    to="camara-puerta-de-entrada"
                    data-tracking="navigation__item"
                  >
                    Camara Puerta de Entrada
                  </Link>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <Link to="paquetes" data-tracking="navigation__item">
                Paquetes
                <img
                  alt="drop-down"
                  src={process.env.PUBLIC_URL + "images/nav-icon-down.png"}
                  style={{ marginLeft: 5 }}
                />
              </Link>
              <span
                className="icon-dropdown"
                data-tracking="navigation__item--dropdown--packages-dropdown"
              ></span>
              <label
                className="icon-dropdown"
                data-tracking="navigation__item--dropdown--packages-dropdown"
              ></label>
              <input type="checkbox" id="dropdown-packages-dropdown" />
              <ul className="submenu">
                <li className="standard">
                  <Link to="paquetes" data-tracking="navigation__item">
                    Sinopsis
                  </Link>
                </li>
                <li className="standard">
                  <Link
                    to="ofertas-especiales"
                    data-tracking="navigation__item"
                  >
                    Ofertas Especiales
                  </Link>
                </li>
                <li className="standard">
                  <Link
                    to="smart-home-automation"
                    data-tracking="navigation__item"
                  >
                    Smart Control
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
