import React from "react";

// Child Components
import Hero from "../../../components/hero/paquetes";
import PackageVivint from "../../../components/packageVivint";
import Products from "../../../components/products";
import Special from "../../../components/featured/special";
import ClientsOpinions from "../../../components/clientsOpinions";

export default function PaquetesPage() {
  return (
    <>
      <Hero />
      <PackageVivint />
      <Products />
      <Special />
      <ClientsOpinions haveQuestion={true} />
    </>
  );
}
