import React from "react";

export default function WithBkg() {
  return (
    <>
      <div className="layout-side-by-side cf-component reverse gray light-content">
        <div className="container">
          <div className="row">
            <section className="image-container small-12 medium-6 large-6 columns">
              <img
                src={process.env.PUBLIC_URL + "images/skypanel.png"}
                alt="tablet"
              />
            </section>
            <section className="content-container small-12 medium-6 large-6 columns">
              <h3>Saluda a la tecnolog&#237;a inteligente</h3>
              <p>
                El panel Vivint SkyControl funciona como el centro de tu
                sistema. Su pantalla t&#225;ctil f&#225;cil de utilizar y su
                dise&#241;o compacto combinan lo atractivo con lo funcional. Tu
                panel aprende y se adapta a tus h&#225;bitos diarios para que
                t&#250; no tengas que mover ni un dedo. As&#237;, Vivint
                SkyControl memoriza tus preferencias de temperatura, tus
                configuraciones de seguridad y m&#225;s. Incluso puedes usar tu
                panel en situaciones de emergencia para hablar directamente con
                un agente de seguridad y as&#237; no desperdiciar el tiempo tan
                valioso.
              </p>
              <ul>
                <li>
                  Tecnolog&#237;a de pantalla t&#225;ctil con un dise&#241;o
                  compacto y f&#225;cil de usar.
                </li>
                <li>Se adapta a tus preferencias personalizadas</li>
                <li>
                  Puedes controlarlo desde tu Smartphone o dispositivo con
                  acceso a internet.
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  ¿Tienes preguntas? Llámanos. <strong>1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Haz clic para Llamar:<strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
