import React from "react";

export default function Prices() {
  return (
    <div className="layout-2-column cf-component package-preview">
      <div className="container">
        <div className="column-wrapper">
          <div data-equalizer className="row">
            <div className="all column-1 medium-6 columns">
              <div className="preview">
                <h5>Smart Home</h5>
                <p>Desde $39.99 al mes</p>
              </div>
            </div>
            <div className="all column-2 medium-6 columns">
              <div className="preview">
                <h5>Smart Home Video</h5>
                <p>Desde $49.99 al mes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
