import React from "react";

export default function Offer() {
  return (
    <>
      <div
        className="layout-feature cf-component hero"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "images/specials-hero.png"
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="columns"></div>
            <div className="feature-content columns">
              <h1>Ofertas especiales de Vivint para nuevos clientes</h1>
              <h4>
                Dise&#241;a tu propio hogar inteligente con Vivint sin todo el
                gasto extra. Vivint te ofrece un sinf&#237;n de ofertas en
                productos para la seguridad del hogar y precios especiales para
                cada nuevo cliente. Aprovecha hoy mismo las promociones y
                descuentos en equipo de calidad para la automatizaci&#243;n de
                tu hogar.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Recibe ofertas por tiempo limitado para nuevos clientes.{" "}
                  <strong>1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Llama ahora: <strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
