import React, { Fragment, useEffect } from "react";

// Components
import Layout from "../components/Layouts";

// Routes
import { Routes } from "./routes";

function App() {
  useEffect(() => {
    console.log("v1.4.0");
  }, []);

  return (
    <Fragment>
      <Layout>
        <Routes />
      </Layout>
    </Fragment>
  );
}

export default App;
