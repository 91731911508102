import React from "react";
import { Switch, Route } from "react-router-dom";

//Pages
import Home from "../pages/home";
import Paquetes from "../pages/paquetes";
import Offer from "../pages/offer";
import SmartHomeAutomation from "../pages/smart-home-automation";
import CamaraPuertaDeEntrada from "../pages/camara-puerta-de-entrada";
import Equipo from "../pages/equipo";

export function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/paquetes" component={Paquetes} />
      <Route exact path="/ofertas-especiales" component={Offer} />
      <Route
        exact
        path="/smart-home-automation"
        component={SmartHomeAutomation}
      />
      <Route
        exact
        path="/camara-puerta-de-entrada"
        component={CamaraPuertaDeEntrada}
      />
      <Route exact path="/equipo" component={Equipo} />
    </Switch>
  );
}
