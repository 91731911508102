import React from "react";

// Child Components
import Hero from "../../../components/hero/equipo";
import Details from "../../../components/packageVivint/details";
import Featured from "../../../components/featured/vint";

export default function EquipoPage() {
  return (
    <>
      <Hero />
      <Details />
      <Featured />
    </>
  );
}
