import React from "react";
import { Link } from "react-router-dom";
export default function Vint() {
  return (
    <>
      <div className="layout-side-by-side cf-component light-gray">
        <div className="container">
          <div className="row">
            <section className="image-container small-12 medium-6 large-6 columns">
              <img
                src={process.env.PUBLIC_URL + "/images/code-lock.png"}
                alt="code"
              />
            </section>
            <section className="content-container small-12 medium-6 large-6 columns">
              <h3>Equipo Vivint</h3>
              <p>
                Los sistemas de seguridad y equipo para la automatizaci&#243;n
                del hogar que ofrece Vivint son inteligentes, econ&#243;micos y
                f&#225;ciles de usar. Probablemente la parte m&#225;s
                inteligente es que el equipo Vivint es 100% inal&#225;mbrico.
                Desp&#237;dete de los molestos cables y los agujeros poco
                atractivos en tu pared. Con la tecnolog&#237;a Z-Wave de Vivint
                te sentir&#225;s seguro y c&#243;modo en tu hogar, y adem&#225;s
                tendr&#225;s el control de todo en la palma de tu mano, desde tu
                <Link to="camara-puerta-de-entrada">
                  c&#225;mara para exteriores
                </Link>
                de Vivint, hasta tu sistema de almacenamiento digital Vivint
                Playback<sup>&reg;</sup>, gracias a las aplicaciones
                m&#243;viles de Vivint.
              </p>
              <ul>
                <li>Sistema inal&#225;mbrico de seguridad para el hogar</li>
                <li>Automatizaci&#243;n residencial inal&#225;mbrica</li>
                <li>Aplicaciones para Smartphone y tabletas</li>
              </ul>
              <p>
                Con tantas opciones para tu hogar, posiblemente necesitar&#225;s
                un poco de orientaci&#243;n. Ll&#225;manos para que conozcas
                m&#225;s sobre la alta tecnolog&#237;a de Vivint y sus productos
                f&#225;ciles de usar.
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  ¿Preguntas? Llámanos hoy mismo.
                  <strong>1-855-950-1599</strong>
                </span>
                <span className="text-mobile">
                  Haz clic para Llamar:<strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
