import React from "react";

export default function PackageVivint() {
  return (
    <>
      <div className="layout-2-column cf-component light-gray">
        <div className="container">
          <div className="row">
            <div className="before columns">
              <h3>Paquetes de Vivint</h3>
            </div>
          </div>
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 small-12 medium-6 large-6 columns">
                <div className="layout-package-card-2 cf-component light-gray">
                  <p className="highlight">&nbsp;</p>
                  <section className="basic protect spanish">
                    <h4>Smart Home</h4>
                    <p className="price">$39.99</p>
                    <p className="disclaimer"></p>
                    <ul>
                      <li>Notificaciones de alarma y de otros tipos</li>
                      <li>Acceso remoto con la app móvil</li>
                      <li>Conectividad para automatización</li>
                      <li>Compatible con Nest, Alexa, y más</li>
                    </ul>
                  </section>
                </div>
              </div>
              <div className="all column-2 small-12 medium-6 large-6 end columns">
                <div className="layout-package-card-2 cf-component light-gray">
                  <p className="highlight visible">Más por tu dinero</p>
                  <section className="basic control spanish">
                    <h4>Smart Home Video</h4>
                    <p className="price">$49.99</p>
                    <p className="disclaimer"></p>
                    <ul>
                      <li>Notificaciones de alarma y de otros tipos</li>
                      <li>Acceso remoto con la app móvil</li>
                      <li>Conectividad para automatización</li>
                      <li>Compatible con Nest, Alexa, y más</li>
                      <li>Equipo con cámara sincronizada</li>
                      <li>Guarda imágenes de video en vivo</li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component overlay has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Lama ahora y obtén una estimación GRATIS.{" "}
                  <strong>1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Llama ahora: <strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
