import React from "react";
import { Link } from "react-router-dom";

export default function CamDoorIn() {
  return (
    <>
      <div className="layout-side-by-side cf-component white reverse orange-disclaimer">
        <div className="container">
          <div className="row">
            <section className="image-container small-12 medium-6 large-6 columns">
              <img
                src={process.env.PUBLIC_URL + "/images/doorbell-side.png"}
                alt="doorbell"
              />
            </section>
            <section className="content-container small-12 medium-6 large-6 columns">
              <h3>
                &#191;Qu&#233; puedes hacer con el timbre con c&#225;mara de
                Vivint?
              </h3>
              <p>
                Cuando dejas entrar a personas en tu vida, lo m&#225;s seguro es
                que tambi&#233;n les abrir&#225;s las puertas de tu casa. Con el
                timbre con c&#225;mara de Vivint puedes ver, escuchar y hablar
                con las personas que llaman a tu puerta, &#161;aunque no
                est&#233;s en casa! Diles adi&#243;s a las visitas indeseables y
                &#225;breles las puertas a tu familia y amigos. Tu timbre con
                c&#225;mara incluso te permite quitar el seguro de tu puerta
                para dejar entrar a tu familia y amigos y recibir notificaciones
                en tu celular o en cualquier otro dispositivo con conexi&#243;n
                de internet. Adem&#225;s, recibir&#225;s la mejor imagen ya sea
                de d&#237;a o de noche gracias a la funci&#243;n de visi&#243;n
                nocturna y a una vista de 180 grados desde tu puerta principal.
                Ya sea que est&#233;s en tu cocina o andes de fiesta con tus
                amigos, tu timbre con c&#225;mara inal&#225;mbrico te
                ayudar&#225; a dejar entrar &#250;nicamente a las personas
                importantes de tu vida.
              </p>
              <ul>
                <li>Detector fotoel&#233;ctrico</li>
                <li>Construido en una alarma 85 cBa</li>
                <li>Bater&#237;a de litio de 5 a&#241;os</li>
                <li>A prueba de alteraciones</li>
              </ul>
              <p>
                El timbre con c&#225;mara de Vivint est&#225; disponible
                exclusivamente con el{" "}
                <Link to="smart-home-automation">paquete Smart Control</Link>.
                Al elegir este paquete hoy mismo, no solo recibir&#225;s un
                timbre con c&#225;mara inal&#225;mbrico sino muchas otras
                funciones automatizadas, por ejemplo, el acceso a tu puerta
                distancia y el control inteligente de temperatura. Son muchas
                las funciones que puedes controlar y personalizar con tu paquete
                VivintSmartHome<sup>&trade;</sup>.
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="layout-hero cf-component dark-gray overlay big-disclaimer">
        <div className="container row">
          <div className="content-wrapper small-12 medium-8 large-6 end columns">
            <section className="content">
              <h3>
                &#191;Cu&#225;les son las funciones personalizables del timbre
                con c&#225;mara?
              </h3>
              <p>
                El timbre inal&#225;mbrico con c&#225;mara ofrece una gran
                variedad de funciones personalizables. Por ejemplo, al utilizar
                la aplicaci&#243;n Vivint Sky, puedes personalizar las
                notificaciones relacionadas con la configuraci&#243;n de
                alarmas, las grabaciones de la c&#225;mara y el seguro de la
                puerta. Adem&#225;s de manejar las notificaciones en tu
                tel&#233;fono, puedes cambiar el sonido del timbre, ajustar el
                volumen y controlar las im&#225;genes de video que aparecen
                cuando las personas tocan el timbre. Y eso no es todo,
                podr&#225;s personalizar las configuraciones de calidad de video
                y encender o apagar la luz de la c&#225;mara LED para maximizar
                tu capacidad de monitorear lo que sucede fuera de tu casa.
                Personalizar tu timbre con c&#225;mara es un proceso f&#225;cil
                que te ayuda a proteger lo que m&#225;s importa.
              </p>
              <ul>
                <li>Notificaciones personalizables</li>
                <li>Puedes cambiar el tono del timbre</li>
                <li>Puedes ajustar las configuraciones de luz y video</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="layout-side-by-side cf-component white">
        <div className="container">
          <div className="row">
            <section className="image-container small-12 medium-6 large-6 columns">
              <img
                src={process.env.PUBLIC_URL + "/images/doorbell-vector.png"}
                alt="doorbell"
              />
            </section>
            <section className="content-container small-12 medium-6 large-6 columns">
              <h3>
                &#191;Cu&#225;les funciones de Vivint puedes personalizar con tu
                tel&#233;fono?
              </h3>
              <p>
                Adem&#225;s de las notificaciones de tu timbre con c&#225;mara,
                puedes controlar muchas de las funciones de VivintSmartHome por
                medio de cualquier dispositivo con conexi&#243;n a internet. Al
                descargar la aplicaci&#243;n gratuita Vivint Sky, podr&#225;s
                ver video en tiempo real, activar y desactivar tu sistema, poner
                y quitar el seguro de tu puerta y abrir y cerrar tu garaje, y
                todo desde la palma de tu mano**. Nunca m&#225;s tendr&#225;s
                que preocuparte por los paquetes entregados en la puerta de tu
                casa o que tus invitados se queden afuera. Con la
                aplicaci&#243;n Vivint Sky te sentir&#225;s m&#225;s seguro
                dentro de tu hogar inteligente.
              </p>
              <ul>
                <li>
                  Ve video grabado y en vivo<sup>**</sup>
                </li>
                <li>
                  Activa y desactiva tu alarma a distancia<sup>**</sup>
                </li>
                <li>
                  Pon y quita el seguro de puerta a distancia<sup>**</sup>
                </li>
              </ul>
              <p>
                Todas estas funciones, incluyendo el timbre con c&#225;mara de
                Vivint, est&#225;n disponibles con el{" "}
                <Link to="smart-home-automation">paquete Smart Control</Link>,
                el cual te permite automatizar tu casa a un precio razonable.{" "}
                <Link to="paquetes">Haz clic aqu&#237;</Link> para conocer
                m&#225;s sobre los paquetes de servicios inteligentes y
                seguridad para el hogar y maximizar las funciones de tu timbre
                inal&#225;mbrico con c&#225;mara hoy mismo.
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Solicita una cotización gratis hoy mismo.{" "}
                  <strong>1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Haz clic para Llamar:<strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
