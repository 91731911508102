import React from "react";

export default function Equipo() {
  return (
    <>
      <div className="layout-feature cf-component hero">
        <div className="container">
          <div className="row">
            <div className="columns"></div>
            <div className="feature-content columns">
              <h1>
                <span>Protege tu vida, automatiza tu hogar con</span> el equipo
                y los servicios de Vivint
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component orange has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Lama ahora y obtén una estimación GRATIS.
                  <strong>1-855-950-1599</strong>
                </span>
                <span className="text-mobile">
                  Llama ahora: <strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
