import React from "react";

export default function Details() {
  return (
    <>
      <div className="layout-feature cf-component white">
        <div className="container">
          <div className="row">
            <div className="columns"></div>
            <div className="feature-content columns">
              <h3>Descripci&#243;n del equipo est&#225;ndar</h3>
              <p>
                Vivint sabe que cada hogar es &#250;nico. Por eso tienes la
                libertad de elegir el equipo de seguridad que mejor satisfaga
                tus necesidades. Elige la mejor combinaci&#243;n para ti y crea
                el paquete personalizado de tus sue&#241;os.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-6-column cf-component equipment-preview">
        <div className="container">
          <div className="row">
            <div className="before columns"></div>
          </div>
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/window-sensor.png"}
                  alt="Window Sensor"
                />
                <p>Sensor de puerta y ventana</p>
              </div>
              <div className="all column-2 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/door-sensor.png"}
                  alt="Door Sensor"
                />
                <p>Sensor de puerta corrediza</p>
              </div>
              <div className="all column-3 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/motion-sensor.png"}
                  alt="Motion Sensor"
                />
                <p>Sensor de movimiento</p>
              </div>
              <div className="all column-4 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/tilt-sensor.png"}
                  alt="Tilt Sensor"
                />
                <p>Sensor de inclinaci&#243;n</p>
              </div>
              <div className="all column-5 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/glass-sensor.png"}
                  alt="Glass Sensor"
                />
                <p>Detector de vidrio roto</p>
              </div>
              <div className="all column-6 small-6 medium-2 end columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/key-fob.png"}
                  alt="Key Fob"
                />
                <p>Llavero</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-5-column cf-component equipment-preview preview-end">
        <div className="container">
          <div className="row">
            <div className="before columns"></div>
          </div>
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 small-6 medium-2 medium-offset-1 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/medical-pendant.png"}
                  alt="Medical Pendant"
                />
                <p>Collar de alerta m&#233;dica</p>
              </div>
              <div className="all column-2 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/carbon-detector.png"}
                  alt="Carbon Detector"
                />
                <p>Detectores de mon&#243;xido de carbono</p>
              </div>
              <div className="all column-3 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/smoke-alarm.png"}
                  alt="Smoke Alarm"
                />
                <p>Detectores de humo</p>
              </div>
              <div className="all column-4 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/temp-sensor.png"}
                  alt="Temp Sensor"
                />
                <p>Sensor de temperatura /inundaci&#243;n</p>
              </div>
              <div className="all column-5 small-6 medium-2 end columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/lamp-module.png"}
                  alt="Lamp Module"
                />
                <p>M&#243;dulo de l&#225;mpara</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-feature cf-component white">
        <div className="container">
          <div className="row">
            <div className="columns"></div>
            <div className="feature-content columns">
              <h3>Descripci&#243;n de los Servicios para el hogar de Vivint</h3>
              <p>
                Como el proveedor n&#250;mero 1 en los Estados Unidos*, Vivint
                ofrece una amplia selecci&#243;n de equipo de primera para la
                automatizaci&#243;n del hogar, as&#237; podr&#225;s automatizar
                tu casa sin problemas. Adem&#225;s del equipo est&#225;ndar,
                puedes personalizar a&#250;n m&#225;s tu paquete incluyendo el
                equipo de automatizaci&#243;n de tu elecci&#243;n.
              </p>
              <p className="disclaimer">
                Fuente: Svanberg, Johan. Smart Homes and Home Automation.
                Gotemburgo, Suecia: Berg Insight, 2016. Impreso. Cuarta
                Edici&#243;n.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-5-column cf-component equipment-preview">
        <div className="container">
          <div className="row">
            <div className="before columns">
              <h4>Servicios residenciales est&#225;ndar</h4>
            </div>
          </div>
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 small-6 medium-2 medium-offset-1 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/doorbell-camera.png"}
                  alt="Doorbell Camera"
                />
                <p>C&#225;mara para el timbre</p>
              </div>
              <div className="all column-2 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/indoor-camera.png"}
                  alt="Indoor Camera"
                />
                <p>C&#225;mara para interiores</p>
              </div>
              <div className="all column-3 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/thermostat.png"}
                  alt="Thermostat"
                />
                <p>Termostato</p>
              </div>
              <div className="all column-4 small-6 medium-2 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/dead-bolt.png"}
                  alt="Dead Bolt"
                />
                <p>Candados/Cerrojos de seguridad</p>
              </div>
              <div className="all column-5 small-6 medium-2 end columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/garage-door.png"}
                  alt="Garage Door"
                />
                <p>Control para la puerta del garaje</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-2-column cf-component equipment-preview">
        <div className="container">
          <div className="row">
            <div className="before columns">
              <h4>Servicios residenciales Premium</h4>
            </div>
          </div>
          <div className="column-wrapper">
            <div data-equalizer className="row">
              <div className="all column-1 small-6 medium-2 medium-offset-4 columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/outdoor-camera.png"}
                  alt="Outdoor Camera"
                />
                <p>C&#225;mara para exteriores</p>
              </div>
              <div className="all column-2 small-6 medium-2 end columns">
                <img
                  src={process.env.PUBLIC_URL + "/images/space-monkey.png"}
                  alt="Space Monkey"
                />
                <p>
                  Vivint Playback<sup>&reg;</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
