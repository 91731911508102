import React from "react";

// Child Components
import Hero from "../../../components/hero/offer";
import Featured from "../../../components/featured/allItems";
import FeaturedFullPicture from "../../../components/featured/fullPicture";

export default function OfferPage() {
  return (
    <>
      <Hero />
      <Featured />
      <FeaturedFullPicture />
    </>
  );
}
