import React from "react";

export default function Paquetes() {
  return (
    <>
      <div className="layout-feature cf-component hero">
        <div className="container">
          <div className="row">
            <div className="columns"></div>
            <div className="feature-content columns">
              <h1>
                Los paquetes y precios de Vivint
                <span>le quedan bien a tu hogar</span>
              </h1>
              <a href="tel:1-855-950-1599">
                <p className="light-blue">
                  Llama hoy mismo.
                  <br />
                  <span>1-855-950-1599</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-call-to-action cf-component blue-gray hide-for-small has-desktop-click">
        <div className="container row">
          <div className="cta-wrapper small-12 columns">
            <h2>
              <a
                href="tel:1-855-950-1599"
                data-tracking="call-to-action__phone"
              >
                <span className="text-main">
                  Lama ahora y obtén una estimación GRATIS.{" "}
                  <strong>1-855-950-1599</strong>
                </span>{" "}
                <span className="text-mobile">
                  Llama ahora: <strong>1-855-950-1599</strong>
                </span>
              </a>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
